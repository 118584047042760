import styled from 'styled-components'
import Login from './components/Login'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 0 46px 0;
  width: 100%;
  min-height: 100vh;
  background: url('/images/home/bg-sale.png');
  background-position: center;
  background-size: cover;

  .container {
    max-width: 1400px;
  }

  .box-title-section {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    margin-bottom: 16px;

    ${({ theme }) => theme.mediaQueries.lg} {
      margin-bottom: 24px;
    }

    .box-title-section-title {
      color: #fdfffa;
      font-size: 22px;
      line-height: 32px;
      font-style: normal;
      font-weight: 700;

      ${({ theme }) => theme.mediaQueries.lg} {
        font-size: 27px;
        line-height: 40px;
      }
    }

    .box-title-section-description {
      color: #fdfffa;
      font-size: 14px;
      line-height: 24px;
      font-style: normal;
      font-weight: 400;
    }
  }

  @keyframes animationBackground {
    0% {
      background-position: 0px;
    }
    100% {
      background-position: 1698px;
    }
  }
`

const Home = () => {
  return (
    <>
      <Wrapper>
        <Login />
      </Wrapper>
    </>
  )
}

export default Home
