/* eslint-disable no-await-in-loop */
import { Col, Form, Input, Row, Spin } from 'antd'
import useToast from 'hooks/useToast'
import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import useGetLogin from 'hooks/useGetLogin'
import { getAuthToken, setAuthToken } from 'helpers/FetchHelper'
import { CACHE_REFRESH_TOKEN } from 'config/constants'
import Content from '../Content'

const WrapContent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 16px;
  padding-top: 200px;

  .form-login {
    width: 600px;
    max-width: 100%;
    padding: 20px 15px;
    margin: auto;
    border: 2px solid #431c60;
    background: rgba(0, 0, 0, 0.8);

    ${({ theme }) => theme.mediaQueries.lg} {
      padding: 20px 50px;
    }

    p {
      color: #fff;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: -0.2px;

      ${({ theme }) => theme.mediaQueries.sm} {
        font-size: 16px;
      }

      ${({ theme }) => theme.mediaQueries.md} {
        font-size: 18px;
      }

      ${({ theme }) => theme.mediaQueries.lg} {
        font-size: 20px;
      }
    }

    button {
      height: 42px;
      padding: 0 40px;
      border-radius: 8px;
      background: linear-gradient(180deg, rgba(60, 8, 126, 0) 0%, rgba(60, 8, 126, 0.32) 100%), rgba(113, 47, 255, 0.12);
      box-shadow: 0px 0px 12px 0px rgba(191, 151, 255, 0.24) inset;
      border: 2px solid #4d2f8c;
      font-weight: 500;

      &:hover {
        background: linear-gradient(180deg, rgba(60, 8, 126, 0) 0%, rgba(60, 8, 126, 0.32) 100%), rgba(113, 47, 255, 1);
      }
    }

    .ant-form-item-explain-error {
      text-align: left;
      font-size: 12px;
    }
  }
`

const Login = () => {
  const [form] = Form.useForm()
  const { toastSuccess, toastError } = useToast()

  const postLogin = useGetLogin()

  const [loading, setLoading] = useState<boolean>(false)
  const [isLogin, setIsLogin] = useState<boolean>(false)

  const handleLogin = useCallback(() => {
    if (!loading) {
      form
        .validateFields()
        .then((values) => {
          setLoading(true)

          postLogin(
            values,
            (res) => {
              setAuthToken(res?.access_token)
              setAuthToken(res?.refresh_token, CACHE_REFRESH_TOKEN)

              setIsLogin(true)
              toastSuccess('logged in successfully!')
              setLoading(false)
            },
            () => {
              setLoading(false)
              toastError('Login failed, please try again later!')
            },
          )
        })
        .catch(() => {
          setLoading(false)
          toastError('Login failed, please try again later!')
        })
    }
  }, [loading, form, postLogin, toastSuccess, toastError])

  useEffect(() => {
    const intervalId = setInterval(() => {
      const authToken = getAuthToken()

      if (authToken) {
        setIsLogin(true)
      } else {
        setIsLogin(false)
      }
    }, 1000)
    return () => clearInterval(intervalId)
  }, [])

  return (
    <WrapContent>
      <div className="container">
        {!isLogin && (
          <div className="form-login">
            <p>Login</p>

            <Form form={form} layout="vertical">
              <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Please input your email!' }]}>
                <Input />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[{ required: true, message: 'Please input your password!' }]}
              >
                <Input.Password autoComplete="on" />
              </Form.Item>

              <button type="submit" className="button-login" onClick={handleLogin}>
                {loading ? <Spin /> : 'Login'}
              </button>
            </Form>
          </div>
        )}

        {isLogin && <Content />}
      </div>
    </WrapContent>
  )
}

export default Login
