import { useCallback } from 'react'
import fetchHelper from 'helpers/FetchHelper'
import { API_PAYX } from 'config/constants/endpoints'

const useGetLogin = () => {
  const getData = useCallback((payload, cbs?: any, cbe?: any) => {
    try {
      fetchHelper
        .fetch(`${API_PAYX}/auth/sign-in-email-bunny`, {
          method: 'POST',
          body: JSON.stringify(payload),
        })
        .then(([resp, status]: any) => {
          if (status === 201 || status === 200) {
            if (cbs) cbs(resp.data)
          } else if (cbe) cbe(resp)
        })
    } catch (error) {
      console.error('useGetLogin', error)
      cbe()
    }
  }, [])

  return getData
}

export default useGetLogin
